import React from 'react'
import { graphql } from 'gatsby'
import ReactMarkdown from 'react-markdown'
import { Helmet } from 'react-helmet'
import * as _ from 'lodash'
import MarkdownIt from 'markdown-it'
import { GatsbyImage } from 'gatsby-plugin-image'

import { isEmail } from '../utils/validation'
import Layout from '../components/App/Layout'
import Navbar from '../components/Common/Navbar'
import PageBanner from '../components/Common/PageBanner'
import Footer from '../components/Common/Footer'

const applyButton = applicationEmailOrUrl => {
  const isApplicationEmail = isEmail(applicationEmailOrUrl)
  const applicationEmailSrc = `mailto:${applicationEmailOrUrl}`

  return (
    <div>
      <p>
        <button
          class="default-btn"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#applyBtnCollapse"
          aria-expanded="false"
          aria-controls="applyBtnCollapse"
        >
          <i class="flaticon-right"></i>Apply for Job
        </button>
      </p>
      <div class="collapse" id="applyBtnCollapse">
        <div class="card card-body">
          To apply for this job,{' '}
          {isApplicationEmail ? 'email us your cover letter and resume to' : 'visit'}:
          {isApplicationEmail ? (
            <a href={applicationEmailSrc}>{applicationEmailOrUrl}</a>
          ) : (
            <a href={applicationEmailOrUrl}>{applicationEmailOrUrl}</a>
          )}
        </div>
      </div>
    </div>
  )
}

const ComponentName = ({ data }) => {
  const {
    title,
    description,
    cover,
    applicationEmailOrUrl,
    formattedPublishedDate,
    publishedFromNow,
    slug,
    position,
    published_at,
    listingExpiryDate,
    address,
    hiringOrganization,
    Meta,
    TwitterMeta,
  } = data.job

  const parsedDescription = new MarkdownIt().render(description)

  const jobPostingSeo = {
    '@context': 'https://schema.org/',
    '@type': 'JobPosting',
    title: position,
    description: parsedDescription,
    identifier: {
      '@type': 'PropertyValue',
      name: _.get(hiringOrganization, 'organizationName'),
      value: slug,
    },
    datePosted: published_at,
    validThrough: listingExpiryDate,
    hiringOrganization: {
      '@type': 'Organization',
      name: _.get(hiringOrganization, 'organizationName'),
      sameAs: _.get(hiringOrganization, 'organizationWebsite'),
      logo: _.get(
        _.first(_.get(hiringOrganization, 'organizationLogo', [])),
        'url'
      ),
    },
    jobLocation: {
      '@type': 'Place',
      address: {
        '@type': 'PostalAddress',
        ..._.omitBy(address, val => !val),
      },
    },
  }

  return (
    <Layout metaTags={Meta} twitterCard={TwitterMeta}>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(jobPostingSeo)}
        </script>
      </Helmet>
      <Navbar />

      <PageBanner
        pageTitle="Job Details"
        homePageText="Careers"
        homePageUrl="/careers"
        activePageText={title}
      />

      <section className="job-details-area ptb-100">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-8 offset-md-2 pb-70">
              <GatsbyImage
                alt={title}
                image={cover.childImageSharp.gatsbyImageData}
              />
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div className="job-details-title">
                <h2>{title}</h2>
                <i className="flaticon-calendar"></i>{' '}
                <strong>{formattedPublishedDate}</strong>
              </div>
              <div className="job-details-desc my-5">
                <ReactMarkdown source={description} />
              </div>
              <div className="job-details-apply">
                {(applicationEmailOrUrl || '').trim()
                  ? applyButton(applicationEmailOrUrl)
                  : ''}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query GetSingleJob($slug: String) {
    job: strapiJobs(slug: { eq: $slug }) {
      title
      position
      cover {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
      applicationEmailOrUrl
      isPositionFilled
      listingExpiryDate(formatString: "YYYY-MM-DD")
      published_at(formatString: "YYYY-MM-DD")
      publishedFromNow: published_at(fromNow: true)
      formattedPublishedDate: published_at(formatString: "MMMM D, YYYY")
      description
      address {
        streetAddress
        addressLocality
        addressRegion
        postalCode
        addressCountry
      }
      hiringOrganization {
        organizationName
        organizationWebsite
        organizationLogo {
          url
        }
      }
      Meta {
        title
        keywords
        description
      }
      TwitterMeta {
        card
      }
    }
  }
`

export default ComponentName
